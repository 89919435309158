import { ActionTree } from "vuex";
import { LOAD_ACCOUNT, LOGOUT, SET_SELECTED_ENTITY, SET_USER, SET_USER_ENTITIES } from "./mutations-types";
import { IAccountState } from "./state";
import { accountClient, msalService, usersClient } from "@/services";

export const actions: ActionTree<IAccountState, any> = {
    loadAccount: (context): void => {
        const account = msalService.getActiveAccount();
        context.commit(LOAD_ACCOUNT, account);
    },

    logout: async (context): Promise<void> => {
        msalService.logoutRedirect({
            postLogoutRedirectUri: "/",
        });

        context.commit(LOGOUT);
    },

    fetchUser: async (context): Promise<void> => {
        const user = await accountClient.getCurrentUser();
        context.commit(SET_USER, user);
    },

    fetchUserEntities: async (context): Promise<void> => {
        const userEntities = await usersClient.getUserEntities(context.state.user.userId);
        context.commit(SET_USER_ENTITIES, userEntities);
    },

    setSelectedEntity: (context, entityId: number): void => {
        context.commit(SET_SELECTED_ENTITY, entityId);
    },
};
