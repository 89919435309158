
export const formatEmail = (email: string): string => {
    let name = email.split("@")[0];
    const domain = email.split("@")[1];
    name = name.length > 5 ? `${name.slice(0, 1)}***${name.slice(name.length - 3, name.length)}` : name;
    const domainEnd = domain.split(".")[1];
    let domainName = domain.split(".")[0];
    domainName = domainName.length > 5 ? `${domainName.slice(0, 1)}***${domainName.slice(domainName.length - 1, domainName.length)}` : domainName;
    return `${name}@${domainName}.${domainEnd}`;
};

