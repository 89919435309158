import { IEntity, IRole } from "@/models";
import { GetterTree } from "vuex";
import { IAccountState } from "./state";

const getters: GetterTree<IAccountState, any> = {
    isAuthenticated: (state): boolean => state.msalAccount != null,

    isAuthorized: (state): boolean => state.user != null,

    userEntities: (state, _, __, rootGetters): IEntity[] => state.userEntities.map(ue => rootGetters["data/entitiesRecords"][ue]),

    userRole: (state, _, rootState): IRole => rootState.data.roles.find((r: IRole) => r.roleId === state.user.roleId),
};

export { getters };
