import { MutationTree } from "vuex";
import * as models from "@/models";
import { IDataState } from "./state";
import * as mutationTypes from "./mutations-types";
import Vue from "vue";

export const mutations: MutationTree<IDataState> = {
    [mutationTypes.SET_BRANDS]: (state, payload: models.IBrand[]) => {
        state.brands = [...payload];
    },

    [mutationTypes.SET_BRAND_QUALITIES]: (state, payload: models.IBrandQuality[]) => {
        state.brandQualities = [...payload];
    },

    [mutationTypes.SET_CHANNELS]: (state, payload: models.IChannel[]) => {
        state.channels = [...payload];
    },

    [mutationTypes.SET_CURRENCIES]: (state, payload: models.ICurrency[]) => {
        state.currencies = [...payload];
    },

    [mutationTypes.SET_ENTITIES]: (state, payload: models.IEntity[]) => {
        state.entities = [...payload].sort((e1,e2) => e1.name.localeCompare(e2.name));
    },

    [mutationTypes.SET_FEATURE_FLAGS]: (state, payload: models.IFeatureFlags) => {
        state.featureFlags = {...payload};
    },

    [mutationTypes.SET_METRICS]: (state, payload: models.IMetric[]) => {
        state.metrics = [...payload];
    },

    [mutationTypes.SET_PUBLISHERS]: (state, payload: models.IPublisher[]) => {
        state.publishers = [...payload];
    },

    [mutationTypes.SET_PURCHASE_ORDERS]: (state, payload: models.IPurchaseOrder[]) => {
        state.purchaseOrders = [...payload];
    },

    [mutationTypes.UPDATE_PURCHASE_ORDERS]: (state, payload: models.IPurchaseOrder[]) => {
        // Merge array with existing
        for (const purchaseOrder of payload) {
            const index = state.purchaseOrders.findIndex(po => po.purchaseOrderId === purchaseOrder.purchaseOrderId);
            if (index > -1) {
                Vue.set(state.purchaseOrders, index, purchaseOrder);
            }
            else {
                state.purchaseOrders.push(purchaseOrder);
            }
        }
    },

    [mutationTypes.UPDATE_VENDORS]: (state, payload: models.IVendor[]) => {
        // Merge array with existing
        for (const vendor of payload) {
            const index = state.vendors.findIndex(v => v.vendorId === vendor.vendorId);
            if (index > -1) {
                Vue.set(state.vendors, index, vendor);
            }
            else {
                state.vendors.push(vendor);
            }
        }
    },

    [mutationTypes.UPDATE_PUBLISHERS]: (state, payload: models.IPublisher[]) => {
        // Merge array with existing
        for (const publisher of payload) {
            const index = state.publishers.findIndex(p => p.publisherId === publisher.publisherId);
            if (index > -1) {
                Vue.set(state.publishers, index, publisher);
            }
            else {
                state.publishers.push(publisher);
            }
        }
    },

    [mutationTypes.UPDATE_ENTITY]: (state, payload: models.IEntity) => {
        // Merge entity with existing
        const index = state.entities.findIndex(e => e.entityId === payload.entityId);
        if (index > -1) {
            Vue.set(state.entities, index, payload);
        }
        else {
            state.entities.push(payload);
        }
    },

    [mutationTypes.UPDATE_REGION]: (state, payload: models.IRegion) => {
        // Merge region with existing
        const index = state.regions.findIndex(r => r.regionId === payload.regionId);
        if (index > -1) {
            Vue.set(state.regions, index, payload);
        }
        else {
            state.regions.push(payload);
        }
    },

    [mutationTypes.UPDATE_REGIONS]: (state, payload: models.IRegion[]) => {
        // Merge region with existing
        for (const region of payload) {
            const index = state.regions.findIndex(r => r.regionId === region.regionId);
            if (index > -1) {
                Vue.set(state.regions, index, payload);
            }
            else {
                state.regions.push(region);
            }
        }
    },

    [mutationTypes.SET_TOUCHPOINTS]: (state, payload: models.ITouchPoint[]) => {
        state.touchPoints = [...payload];
    },

    [mutationTypes.SET_VENDORS]: (state, payload: models.IVendor[]) => {
        state.vendors = [...payload];
    },

    [mutationTypes.SET_SOURCES]: (state, payload: models.ISource[]) => {
        state.sources = [...payload];
    },

    [mutationTypes.SET_SOURCE_ENTITIES]: (state, payload: models.ISourceEntity[]) => {
        state.sourceEntities = [...payload];
    },

    [mutationTypes.SET_SOURCE_TOUCHPOINTS]: (state, payload: models.ISourceTouchpoint[]) => {
        state.sourceTouchpoints = [...payload];
    },

    [mutationTypes.SET_REGIONS]: (state, payload: models.IRegion[]) => {
        state.regions = [...payload];
    },

    [mutationTypes.SET_ROLES]: (state, payload: models.IRole[]) => {
        state.roles = [...payload];
    },

    [mutationTypes.SET_DEFAULT_TOUCHPOINT_METRICS]: (state, payload: models.ITouchPointMetric[]) => {
        state.defaultTouchPointMetrics = [...payload];
    },

    [mutationTypes.SET_TOUCHPOINT_METRICS]: (state, payload: models.ITouchPointMetric[]) => {
        // Merge array with existing
        for (const touchPointMetric of payload) {
            const index = state.touchPointMetrics.findIndex(tpm => tpm.touchPointMetricId === touchPointMetric.touchPointMetricId);
            if (index > -1) {
                Vue.set(state.touchPointMetrics, index, touchPointMetric);
            }
            else {
                state.touchPointMetrics.push(touchPointMetric);
            }
        }
    },

    [mutationTypes.SET_TOUCHPOINT_EXCEPTIONS]: (state, payload: models.ITouchPointException[]) => {
        state.touchPointExceptions = [...payload];
    },

    [mutationTypes.SET_TOUCHPOINT_METRIC_EXCEPTIONS]: (state, payload: models.ITouchPointMetricException[]) => {
        state.touchPointMetricExceptions = [...payload];
    },

    [mutationTypes.SET_ENTITY_DISPLAY_FORMAT]: (state, payload: models.IEntityDisplayFormat[]) => {
        state.entityDisplayFormats = [...payload];
    },

    [mutationTypes.UPDATE_ENTITY_DISPLAY_FORMAT]: (state, payload: models.IEntityDisplayFormat) => {
        // Merge entity with existing
        const index = state.entityDisplayFormats.findIndex(e => e.entityDisplayFormatId === payload.entityDisplayFormatId);
        if (index > -1) {
            Vue.set(state.entityDisplayFormats, index, payload);
        }
        else {
            state.entityDisplayFormats.push(payload);
        }
    },
};
