export class RouteNames {
    public static readonly home = "home";

    public static readonly campaignsBoard = "campaigns-board";

    public static readonly campaignDetails = "campaign-details";

    public static readonly preCampaign = "pre-campaign";

    public static readonly createCampaign = "create-campaign";

    public static readonly editCampaign = "edit-campaign";

    public static readonly campaignValidation = "validation-campaign";

    public static readonly campaignValidationDetails = "validation-campaign-details";

    public static readonly campaignValidationData = "validation-campaign-data";

    public static readonly touchPointsValidation = "validation-touchpoints";

    public static readonly touchpointValidationDetails = "validation-touchpoint-details";

    public static readonly manageMarketSettings = "manage-market-settings";

    public static readonly manageUsers = "manage-users";

    public static readonly manageSourceUsers = "manage-source-users";

    public static readonly manageSourceEntities = "manage-source-entities";

    public static readonly monitoringDashboard = "monitoring-dashboard";

    public static readonly searchIdTask = "search-id-task";

    public static readonly settings = "settings";

    public static readonly userSettings = "user-settings";

    public static readonly unauthorized = "unauthorized";

    public static readonly notFound = "not-found";
}
