import { DirectiveOptions } from "vue";
import { VNode } from "vue/types/umd";
import { DirectiveBinding } from "vue/types/options";
import set from "lodash.set";
import { PrCheckbox, PrDatepicker, PrInput, PrRadioGroup, PrSelect, PrTextarea, PrToggle } from "@pernod-ricard/design-system/dist/components";

// Store elements and their event handler
const eventMap = new WeakMap<HTMLElement, (...args: any) => void>();

export const prModelDirective: DirectiveOptions = {
    bind: (el, binding, vnode) => {
        switch (vnode.tag) {
            case "pr-checkbox": {
                (el as unknown as PrCheckbox).checked = binding.value;
                const inputHandler = event => inputValueHandler(event.detail, binding, vnode);
                eventMap.set(el, inputHandler);

                el.addEventListener("change", inputHandler);
                break;
            }

            case "pr-datepicker": {
                (el as unknown as PrDatepicker).value = binding.value;
                const inputHandler = event => inputValueHandler(event.detail, binding, vnode);
                eventMap.set(el, inputHandler);

                el.addEventListener("change", inputHandler);
                break;
            }

            case "pr-input": {
                (el as unknown as PrInput).value = binding.value;
                const inputHandler = event => inputValueHandler(event.target.value, binding, vnode);
                eventMap.set(el, inputHandler);

                el.addEventListener("input", inputHandler);
                break;
            }

            case "pr-select": {
                (el as unknown as PrSelect).value = binding.value;
                const inputHandler = event => inputValueHandler(event.target.value, binding, vnode);
                eventMap.set(el, inputHandler);

                el.addEventListener("change", inputHandler);
                break;
            }

            case "pr-radio-group": {
                (el as unknown as PrRadioGroup).value = binding.value;
                const inputHandler = event => inputValueHandler(event.target.value, binding, vnode);
                eventMap.set(el, inputHandler);

                el.addEventListener("change", inputHandler);
                break;
            }

            case "pr-toggle": {
                (el as unknown as PrToggle).checked = binding.value;
                const inputHandler = event => inputValueHandler(event.target.checked, binding, vnode);
                eventMap.set(el, inputHandler);

                el.addEventListener("change", inputHandler);
                break;
            }

            case "pr-textarea": {
                (el as unknown as PrTextarea).value = binding.value;
                const inputHandler = event => inputValueHandler(event.target.value, binding, vnode);
                eventMap.set(el, inputHandler);

                el.addEventListener("input", inputHandler);
                break;
            }

            default: {
                (el as HTMLInputElement).value = binding.value;
                const inputHandler = event => inputValueHandler(event.target.value, binding, vnode);
                eventMap.set(el, inputHandler);

                el.addEventListener("input", inputHandler);
                break;
            }
        }
    },

    componentUpdated: (el, binding) => {
        (el as HTMLInputElement).value = binding.value;
    },

    unbind: (el) => {
        const handler = eventMap.get(el);
        el.removeEventListener(el as any, handler);
    },
};

const inputValueHandler = (value, binding: DirectiveBinding, vnode: VNode): void => {
    // Parse string value to float, if it failed return original string
    if (binding.modifiers.number) {
        const parsed = parseFloat(value);
        value = isNaN(parsed) ? value : parsed;
    }

    set(vnode.context, binding.expression, value);
};
