import Vue from "vue";
import Vuex from "vuex";
import { account } from "./account";
import { data } from "./data";
import { IAccountState } from "./account/state";
import { IDataState } from "./data/state";
import { campaignsHubPlugin } from "@/services/campaignsHub";

Vue.use(Vuex);

export interface IState {
    account: IAccountState;
    data: IDataState;
}

export const store = new Vuex.Store<IState>({
    modules: {
        account,
        data,
    },
    plugins: [campaignsHubPlugin()],
});
