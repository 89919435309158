import * as models from "@/models";

interface IDataState {
    brands: models.IBrand[];
    brandQualities: models.IBrandQuality[];
    channels: models.IChannel[];
    currencies: models.ICurrency [];
    entities: models.IEntity[];
    entityDisplayFormats: models.IEntityDisplayFormat[];
    featureFlags: models.IFeatureFlags;
    metrics: models.IMetric[];
    publishers: models.IPublisher[];
    purchaseOrders: models.IPurchaseOrder[];
    regions: models.IRegion[];
    roles: models.IRole[];
    sources: models.ISource[];
    sourceEntities: models.ISourceEntity[];
    sourceTouchpoints: models.ISourceTouchpoint[];
    touchPointMetrics: models.ITouchPointMetric[];
    defaultTouchPointMetrics: models.ITouchPointMetric[];
    touchPoints: models.ITouchPoint[];
    touchPointExceptions: models.ITouchPointException[];
    touchPointMetricExceptions: models.ITouchPointMetricException[];
    vendors: models.IVendor[];
}

const initialState: IDataState = {
    brands: [],
    brandQualities: [],
    channels: [],
    currencies: [],
    entities: [],
    entityDisplayFormats: [],
    featureFlags: null,
    metrics: [],
    publishers: [],
    purchaseOrders: [],
    regions: [],
    roles: [],
    sources: [],
    sourceEntities: [],
    sourceTouchpoints: [],
    touchPointMetrics: [],
    defaultTouchPointMetrics: [],
    touchPoints: [],
    touchPointExceptions: [],
    touchPointMetricExceptions: [],
    vendors: [],
};

export { IDataState, initialState };
