import Vue from "vue";
import { Store } from "vuex";
import * as signalR from "@microsoft/signalr";
import { settings } from "@/settings";
import { IState } from "@/store";
import { scopes, msalService } from "./MsalService";
import { IImport, IPublisher, IPurchaseOrder, IVendor } from "@/models";
import { ICampaignStatsSignal, ICampaignTaskItemSignal, ITouchPointStatsSignal } from "@/models/signalR";

const campaignsHub = new signalR.HubConnectionBuilder()
    .configureLogging(settings.signalR.logLevel)
    .withUrl(settings.signalR.url + "/campaigns", { accessTokenFactory: async () => msalService.getAccessToken(scopes) })
    .withAutomaticReconnect()
    .build();

// Use a Vue component as event bus
// Inspired by: https://www.dotnetcurry.com/aspnet-core/1480/aspnet-core-vuejs-signalr-app
const eventBus = new Vue();

Vue.prototype.$campaignsHub = eventBus;

// Translate SignalR messages to events
campaignsHub.on("campaignsStatsUpdated", (stats: ICampaignStatsSignal[]) => {
    for (const campaignStats of stats) {
        eventBus.$emit("campaign-stats-updated", campaignStats);
    }
});

campaignsHub.on("touchPointsStatsUpdated", (stats: ITouchPointStatsSignal[]) => {
    for (const touchPointStats of stats) {
        eventBus.$emit("touchpoint-stats-updated", touchPointStats);
    }
});

campaignsHub.on("taskItemCompleted", (campaignId: number, campaignTaskItemId: number, areAllTasksCompleted: boolean) => {
    eventBus.$emit("task-item-completed", campaignId, campaignTaskItemId, areAllTasksCompleted);
});

campaignsHub.on("taskItemsCompleted", (campaignId: number, campaignTaskItemIds: number[]) => {
    for (const taskItemId of campaignTaskItemIds) {
        eventBus.$emit("task-item-completed", campaignId, taskItemId);
    }
});

campaignsHub.on("taskItemsValidated", (tasks: ICampaignTaskItemSignal[]) => {
    for (const task of tasks) {
        eventBus.$emit("task-item-validated", task.campaignId, task.campaignTaskItemId, task.validator);
    }
});

campaignsHub.on("taskItemsRejected", (tasks: ICampaignTaskItemSignal[]) => {
    for (const task of tasks) {
        eventBus.$emit("task-item-rejected", task.campaignId, task.campaignTaskItemId);
    }
});

campaignsHub.on("taskGroupCompleted", (campaignId: number) => {
    eventBus.$emit("task-group-completed", campaignId);
});

campaignsHub.on("taskGroupIncomplete", (campaignId: number) => {
    eventBus.$emit("task-group-incomplete", campaignId);
});

// Translate SignalR messages to events
campaignsHub.on("taskGroupImportFinished", (campaignId: number, status: IImport) => {
    eventBus.$emit("task-group-import-finished", campaignId, status);
});

// Vuex store plugin
// Starts hub on user account load (to ensure access token is available)
// and allows registering event handlers linked to store
const campaignsHubPlugin = () => async (store: Store<IState>): Promise<void> => {
    // Register global event handlers linked to store here
    // campaignsHub.on(...

    campaignsHub.on("purchaseOrdersUpdated", (purchaseOrders: IPurchaseOrder[]) => {
        store.commit("data/UPDATE_PURCHASE_ORDERS", purchaseOrders);
    });

    campaignsHub.on("vendorsUpdated", (vendors: IVendor[]) => {
        store.commit("data/UPDATE_VENDORS", vendors);
    });

    campaignsHub.on("publishersUpdated", (publishers: IPublisher[]) => {
        store.commit("data/UPDATE_PUBLISHERS", publishers);
    });

    store.subscribe(mutation => {
        if (mutation.type === "account/SET_USER" &&
            store.getters["account/isAuthorized"] &&
            campaignsHub.state === signalR.HubConnectionState.Disconnected) {
            campaignsHub.start();
        }
    });
};

export { campaignsHub, campaignsHubPlugin };
