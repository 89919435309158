import { Module } from "vuex";
import { IDataState, initialState } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const data: Module<IDataState, any> = {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

export { data };
