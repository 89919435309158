import { ISourceUserReal, IUser, IUserCreation } from "@/models";
import { ISourceUser } from "@/models/ISourceUser";
import { BaseClient } from "./BaseClient";

class UsersClient extends BaseClient {

    public constructor() {
        super("/users");
    }

    public async getUsers(includeEntities = false): Promise<IUser[]> {
        const query = includeEntities ? "?includeEntities=true" : "";

        const result = await this.get<IUser[]>(query, "Error while fetching users");
        return result ?? [];
    }

    public async getExternalUsers(entityId: number, agencyIds: string[] = []): Promise<IUser[]> {
        const queryString = agencyIds.map(n => `agencyIds=${n}`).join("&");
        const url = `/${entityId}/external?${queryString}`;
        const result = await this.get<IUser[]>(url, "Error while fetching users");
        return result ?? [];
    }

    public async getExternalCampaignUsers(campaignId: number): Promise<IUser[]> {
        const url = `/campaign/${campaignId}/external`;
        const result = await this.get<IUser[]>(url, "Error while fetching users");
        return result ?? [];
    }

    public async getInternalUsers(entityId: number): Promise<IUser[]> {
        const url = entityId + "/internal/";
        const result = await this.get<IUser[]>(url, "Error while fetching users");
        return result ?? [];
    }

    public async getInternalCampaignUsers(campaignId: number): Promise<IUser[]> {
        const url = `/campaign/${campaignId}/internal`;
        const result = await this.get<IUser[]>(url, "Error while fetching users");
        return result ?? [];
    }

    public async getExternalUsersAssociatedToUserAgency(): Promise<IUser[]> {
        const url = "external";

        const result = await this.get<IUser[]>(url, "Error while fetching users");
        return result ?? [];
    }

    public async getUserEntities(userId: number): Promise<number[]> {
        const userAffiliates = await this.get<number[]>(`/${userId}/entityIds`, "Error while fetching user entities");
        return userAffiliates ?? [];
    }

    public async getUserAgencyIdByEntityId(userId: number, entityId: number): Promise<number> {
        return await this.get<number>(`${userId}/${entityId}/agencyId`,"Error while fetching user agency");
    }

    public async createUser(users: IUserCreation[]): Promise<IUser[]> {
        const response = await this.apiClient.post<IUser[]>("", users);

        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        else {
            throw response;
        }
    }

    public async updateUser(user: IUserCreation): Promise<IUser> {
        const response = await this.apiClient.post<IUser>("/update", user);

        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        else {
            throw response;
        }
    }

    public async getSourceUsers(): Promise<ISourceUser[]> {
        const url = "/source-users";
        const result = await this.get<ISourceUser[]>(url, "Error while fetching source users");
        return result ?? [];
    }

    public async createOrUpdateSourceUser(users: ISourceUserReal): Promise<ISourceUserReal> {
        const response = await this.apiClient.post<ISourceUserReal>("source-users", users);

        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        else {
            throw response;
        }
    }
}

const usersClient = new UsersClient();

export { UsersClient, usersClient };

