const SET_BRANDS = "SET_BRANDS";
const SET_BRAND_QUALITIES = "SET_BRAND_QUALITIES";
const SET_CHANNELS = "SET_CHANNELS";
const SET_CURRENCIES = "SET_CURRENCIES";
const SET_ENTITIES = "SET_ENTITIES";
const SET_ENTITY_DISPLAY_FORMAT = "SET_ENTITY_DISPLAY_FORMAT";
const SET_FEATURE_FLAGS = "SET_FEATURE_FLAGS";
const SET_METRICS = "SET_METRICS";
const SET_PUBLISHERS = "SET_PUBLISHERS";
const SET_PURCHASE_ORDERS = "SET_PURCHASE_ORDERS";
const SET_REGIONS = "SET_REGIONS";
const SET_ROLES = "SET_ROLES";
const SET_SOURCES = "SET_SOURCES";
const SET_SOURCE_ENTITIES = "SET_SOURCE_ENTITIES";
const SET_SOURCE_TOUCHPOINTS = "SET_SOURCE_TOUCHPOINTS";
const SET_DEFAULT_TOUCHPOINT_METRICS = "SET_DEFAULT_TOUCHPOINT_METRICS";
const SET_TOUCHPOINT_METRICS = "SET_TOUCHPOINT_METRICS";
const SET_TOUCHPOINTS = "SET_TOUCHPOINTS";
const SET_TOUCHPOINT_EXCEPTIONS = "SET_TOUCHPOINT_EXCEPTIONS";
const SET_TOUCHPOINT_METRIC_EXCEPTIONS = "SET_TOUCHPOINT_METRIC_EXCEPTIONS";
const SET_VENDORS = "SET_VENDORS";
const UPDATE_ENTITY = "UPDATE_ENTITY";
const UPDATE_ENTITY_DISPLAY_FORMAT = "UPDATE_ENTITY_DISPLAY_FORMAT";
const UPDATE_PUBLISHERS = "UPDATE_PUBLISHERS";
const UPDATE_PURCHASE_ORDERS = "UPDATE_PURCHASE_ORDERS";
const UPDATE_REGION = "UPDATE_REGION";
const UPDATE_REGIONS = "UPDATE_REGIONS";
const UPDATE_VENDORS = "UPDATE_VENDORS";


export {
    SET_BRANDS,
    SET_BRAND_QUALITIES,
    SET_CHANNELS,
    SET_CURRENCIES,
    SET_ENTITIES,
    SET_ENTITY_DISPLAY_FORMAT,
    SET_FEATURE_FLAGS,
    SET_METRICS,
    SET_PUBLISHERS,
    SET_PURCHASE_ORDERS,
    SET_REGIONS,
    SET_ROLES,
    SET_SOURCES,
    SET_SOURCE_ENTITIES,
    SET_SOURCE_TOUCHPOINTS,
    SET_TOUCHPOINTS,
    SET_DEFAULT_TOUCHPOINT_METRICS,
    SET_TOUCHPOINT_METRICS,
    SET_TOUCHPOINT_EXCEPTIONS,
    SET_TOUCHPOINT_METRIC_EXCEPTIONS,
    SET_VENDORS,
    UPDATE_ENTITY,
    UPDATE_ENTITY_DISPLAY_FORMAT,
    UPDATE_PUBLISHERS,
    UPDATE_PURCHASE_ORDERS,
    UPDATE_REGION,
    UPDATE_REGIONS,
    UPDATE_VENDORS,
};
