import { ISourceCampaignEditRules } from "@/models/ISourceCampaignEditRules";
import { ISourceTouchpoint } from "@/models";
import { BaseClient } from "./BaseClient";

class SourceClient extends BaseClient {

    public constructor() {
        super("/source");
    }

    public async getSourceCampaignEditRules(sourceId: number): Promise<ISourceCampaignEditRules | null> {
        return await this.get<ISourceCampaignEditRules | null>("/edit-rules/" + sourceId, "Could not find source edit rules");
    }

    public async getSourceTouchpoints(): Promise<ISourceTouchpoint[]> {
        return await this.get<ISourceTouchpoint[]>("/touchpoints", "Could not fetch source touchpoint");
    }
}

const sourceClient = new SourceClient();

export { SourceClient, sourceClient };
