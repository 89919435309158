import { ActionTree } from "vuex";
import { referencesClient, sourceClient, sourceEntityClient, featureFlagsClient, touchPointAndMetricExceptionsClient } from "@/services";
import { IDataState } from "./state";
import * as mutationsTypes from "./mutations-types";
import { store } from "..";
import { ITouchPointMetric } from "@/models";

export const actions: ActionTree<IDataState, any> = {
    fetchBaseData: async (context): Promise<void> => {
        await Promise.all([
            context.dispatch("fetchBrands"),
            context.dispatch("fetchBrandQualities"),
            context.dispatch("fetchChannels"),
            context.dispatch("fetchCurrencies"),
            context.dispatch("fetchEntities"),
            context.dispatch("fetchFeatureFlags"),
            context.dispatch("fetchMetrics"),
            context.dispatch("fetchPublishers"),
            context.dispatch("fetchTouchpoints"),
            context.dispatch("fetchVendors"),
            context.dispatch("fetchPurchaseOrders"),
            context.dispatch("fetchSources"),
            context.dispatch("fetchSourceEntities"),
            context.dispatch("fetchRegions"),
            context.dispatch("fetchRoles"),
            context.dispatch("fetchSourceTouchpoints"),
            context.dispatch("fetchTouchpointException"),
            context.dispatch("fetchDefaultTouchpointMetrics"),
            context.dispatch("fetchEntityDisplayFormat"),
        ]);
    },

    fetchBrands: async (context): Promise<void> => {
        const brands = await referencesClient.getBrands();
        context.commit(mutationsTypes.SET_BRANDS, brands);
    },

    fetchBrandQualities: async (context): Promise<void> => {
        const brandQualities = await referencesClient.getBrandQualities();
        context.commit(mutationsTypes.SET_BRAND_QUALITIES, brandQualities);
    },

    fetchChannels: async (context): Promise<void> => {
        const channels = await referencesClient.getChannels();
        context.commit(mutationsTypes.SET_CHANNELS, channels);
    },

    fetchCurrencies: async (context): Promise<void> => {
        const currencies = await referencesClient.getCurrencies();
        context.commit(mutationsTypes.SET_CURRENCIES, currencies);
    },

    fetchEntities: async (context): Promise<void> => {
        const entities = await referencesClient.getEntities();
        context.commit(mutationsTypes.SET_ENTITIES, entities);
    },

    fetchFeatureFlags: async (context): Promise<void> => {
        const featureFlags = await featureFlagsClient.getAll();
        context.commit(mutationsTypes.SET_FEATURE_FLAGS, featureFlags);
    },

    fetchMetrics: async (context): Promise<void> => {
        const metrics = (await referencesClient.getMetrics()).sort((a, b) => a.order - b.order);
        context.commit(mutationsTypes.SET_METRICS, metrics);
    },

    fetchPublishers: async (context): Promise<void> => {
        const publishers = await referencesClient.getPublishers();
        context.commit(mutationsTypes.SET_PUBLISHERS, publishers);
    },

    fetchTouchpoints: async (context): Promise<void> => {
        const touchpoints = await referencesClient.getTouchPoints();
        context.commit(mutationsTypes.SET_TOUCHPOINTS, touchpoints);
    },

    fetchTouchpointException: async (context): Promise<void> => {
        const touchpointExceptions = await touchPointAndMetricExceptionsClient.getTouchPointExceptions();
        context.commit(mutationsTypes.SET_TOUCHPOINT_EXCEPTIONS, touchpointExceptions);
    },

    fetchVendors: async (context): Promise<void> => {
        const vendors = await referencesClient.getVendors();
        context.commit(mutationsTypes.SET_VENDORS, vendors);
    },

    fetchPurchaseOrders: async (context): Promise<void> => {
        const purchaseOrders = await referencesClient.getPurchaseOrders();
        context.commit(mutationsTypes.SET_PURCHASE_ORDERS, purchaseOrders);
    },

    fetchSources: async (context): Promise<void> => {
        const sources = await referencesClient.getSources();
        context.commit(mutationsTypes.SET_SOURCES, sources);
    },

    fetchSourceEntities: async (context): Promise<void> => {
        const sourceEntities = await sourceEntityClient.getSourceEntities();
        context.commit(mutationsTypes.SET_SOURCE_ENTITIES, sourceEntities);
    },

    fetchRegions: async (context): Promise<void> => {
        const regions = await referencesClient.getRegions();
        context.commit(mutationsTypes.SET_REGIONS, regions);
    },

    fetchRoles: async (context): Promise<void> => {
        const roles = await referencesClient.getRoles();
        context.commit(mutationsTypes.SET_ROLES, roles);
    },

    fetchEntityDisplayFormat: async (context): Promise<void> => {
        const entityDisplayFormats = await referencesClient.getEntityDisplayFormats();
        context.commit(mutationsTypes.SET_ENTITY_DISPLAY_FORMAT, entityDisplayFormats);
    },

    fetchTouchpointMetrics: async (context, payload: {touchPointIds: number[]}): Promise<ITouchPointMetric[]> => {
        const tpMetricException = context.state.touchPointMetricExceptions.filter(x => x.entityId === context.rootState.account.selectedEntityId);

        const missingTouchPointMetricIds = payload.touchPointIds.filter(tpId => store.state.data.touchPointMetrics.find(tpm => tpm.touchPointId === tpId) === undefined);
        if (missingTouchPointMetricIds.length > 0) {
            const touchPointMetrics = await referencesClient.getTouchPointMetricsByTouchpoints(missingTouchPointMetricIds);
            context.commit(mutationsTypes.SET_TOUCHPOINT_METRICS, touchPointMetrics);
        }

        const tpMetrics = store.state.data.touchPointMetrics.filter(tpm => payload.touchPointIds.includes(tpm.touchPointId));
        tpMetricException.forEach(element => {
            if (tpMetrics.find(x => x.metricId === element.metricId && x.touchPointId === element.touchPointId)) {
                tpMetrics.find(x => x.metricId === element.metricId && x.touchPointId === element.touchPointId).isMandatory = true;
                const replacedTouchPointMetric = tpMetrics.find(x => x.metricId === element.replacedMetricId && x.touchPointId === element.touchPointId);
                if (replacedTouchPointMetric) {
                    replacedTouchPointMetric.isMandatory = false;
                }
            }
            else if (tpMetrics.find(x => x.touchPointId === element.touchPointId)) {
                const tp = store.state.data.touchPoints.find(x => x.touchPointId === element.touchPointId);
                const metric = store.state.data.metrics.find(m => m.metricId === element.metricId);
                tpMetrics.push({
                    touchPointMetricId: 0,
                    touchPointId: tp.touchPointId,
                    touchPointName: tp.name,
                    parentTouchPointId: tp.parentTouchPointId,
                    touchpointTypeId: tp.touchPointTypeId,
                    metricId: element.metricId,
                    metricTypeId: metric.metricTypeId,
                    metricType: metric.metricType,
                    metricName: metric.name,
                    externalId: metric.externalId,
                    isMandatory: true,
                });
                const replacedTouchPointMetric = tpMetrics.find(x => x.metricId === element.replacedMetricId && x.touchPointId === element.touchPointId);
                if (replacedTouchPointMetric) {
                    replacedTouchPointMetric.isMandatory = false;
                }
            }
        });
        return tpMetrics;
    },

    fetchDefaultTouchpointMetrics: async (context): Promise<void> => {
        const touchpointsMetrics = await referencesClient.getTouchPointMetrics();
        context.commit(mutationsTypes.SET_DEFAULT_TOUCHPOINT_METRICS, touchpointsMetrics);
    },

    fetchSourceTouchpoints: async (context): Promise<void> => {
        const sourceTouchpoints = await sourceClient.getSourceTouchpoints();
        context.commit(mutationsTypes.SET_SOURCE_TOUCHPOINTS, sourceTouchpoints);
    },

    fetchTouchpointExceptions: async (context, entityId: number): Promise<void> => {
        if (entityId !== undefined) {
            const touchpointExceptions = await touchPointAndMetricExceptionsClient.getTouchPointExceptionsByEntityId(entityId);
            context.commit(mutationsTypes.SET_TOUCHPOINT_EXCEPTIONS, touchpointExceptions);
        }
    },

    fetchTouchpointMetricExceptions: async (context, entityId: number): Promise<void> => {
        if (entityId !== undefined) {
            const touchpointMetricExceptions = await touchPointAndMetricExceptionsClient.getTouchPointMetricExceptionsByEntityId(entityId);
            context.commit(mutationsTypes.SET_TOUCHPOINT_METRIC_EXCEPTIONS, touchpointMetricExceptions);
        }
    },
};
