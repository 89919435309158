export class LocalStorageNames {
    public static readonly campaignsFilter = "campaignsFilter";

    public static readonly lastRouteName = "lastRouteName";

    public static readonly lastRoutePath = "lastRoutePath";

    public static readonly releaseSeenDate = "user.releaseSeenDate";

    public static readonly surveySeenDate = "user.surveySeenDate";

    public static readonly userLastLogin = "user.lastLogin";
}
