import { IUser } from "@/models";
import { BaseClient } from "./BaseClient";

class AccountClient extends BaseClient {

    public constructor() {
        super("/account");
    }

    public async getCurrentUser(): Promise<IUser> {
        return await this.get<IUser>("", "Error while fetching user details");
    }

    public async trackLogin(): Promise<void> {
        return await this.post("/track-login");
    }
}

const accountClient = new AccountClient();

export { AccountClient, accountClient };
