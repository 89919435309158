import Vue from "vue";
import { NavigationGuard } from "vue-router";
import { WaitKeys } from "@/helpers/WaitKeys";
import { msalService } from "@/services";
import { store } from "@/store";
import { RouteNames } from "./RouteNames";

export const authGuard: NavigationGuard = async (to, from, next) => {
    Vue.nextTick(async () => {
        store.dispatch("wait/start", WaitKeys.login);

        // Login with Azure AD
        const loggedIn = await msalService.login();
        if (!loggedIn) {
            return next({ name: RouteNames.unauthorized });
        }

        if (to.meta.anonymous) {
            return next();
        }

        // Load user account from Azure AD
        await store.dispatch("account/loadAccount");

        // Check user is authenticated with Azure AD
        if (!store.getters["account/isAuthenticated"]) {
            return next({ name: RouteNames.unauthorized });
        }

        // Fetch user data if not loaded
        if (!store.state.account.user) {
            store.dispatch("wait/start", WaitKeys.userData);

            // Fetch Extract user data
            await store.dispatch("account/fetchUser");

            // Check user's data is loaded, no data means it does not exist
            if (!store.getters["account/isAuthorized"]) {
                return next({ name: RouteNames.unauthorized });
            }

            // Pre-fetch user's assigned entities
            await store.dispatch("account/fetchUserEntities");

            store.dispatch("wait/end", WaitKeys.userData);
        }

        store.dispatch("wait/end", WaitKeys.login);
        return next();
    });
};
