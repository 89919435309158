import { IValidationScope } from "@/models";
import { BaseClient } from "./BaseClient";
import { AxiosResponse } from "axios";

class UserSettingsClient extends BaseClient {

    public constructor() {
        super("/user-settings");
    }

    public async getValidationScope(entityId: number): Promise<IValidationScope | null> {
        return await this.get<IValidationScope | null>(`/validation-scope/${entityId}`, "Could not find validation scope");
    }

    public async createOrUpdateValidationScope(validationScope: IValidationScope): Promise<IValidationScope[]> {
        return await this.post("/validation-scope", validationScope, "Could not save validation scope");
    }

    public async deleteValidationScope(entityId: number): Promise<AxiosResponse> {
        return await this.delete("/validation-scope/" + entityId);
    }
}

const userSettingsClient = new UserSettingsClient();

export { UserSettingsClient, userSettingsClient };

