import { buildLookup, buildRecords } from "@/helpers";
import { IBrand, IBrandQuality, IChannel, ICurrency, IEntity, IMetric, IPublisher, ITouchPoint, IVendor, ISource, IRegion} from "@/models";
import {ITouchPointMetricException, IEntityDisplayFormat, ISourceEntity} from "@/models";
import { GetterTree } from "vuex";
import { IDataState } from "./state";
import { ITouchPointException } from "@/models/ITouchPointException";

const getters: GetterTree<IDataState, any> = {
    brandsRecords: (state): Record<number, IBrand> =>
        buildRecords(state.brands, (brand) => brand.brandId),

    brandQualitiesRecords: (state): Record<number, IBrandQuality> =>
        buildRecords(state.brandQualities, (brandQuality) => brandQuality.brandQualityId),

    brandsHierarchy: (state): IBrand[] => {
        const brandQualitiesLookup = buildLookup(state.brandQualities, bq => bq.brandId);
        return state.brands.map<IBrand>(brand => ({
            ...brand,
            brandQualities: brandQualitiesLookup[brand.brandId] ?? [],
        }));
    },

    channelsRecords: (state): Record<number, IChannel> =>
        buildRecords(state.channels, (channel) => channel.channelId),

    /** Get a currencies dictionary (by id) */
    currenciesRecords: (state): Record<number, ICurrency> =>
        buildRecords(state.currencies, currency => currency.currencyId),

    /** Get a entities dictionary (by id) */
    entitiesRecords: (state): Record<number, IEntity> =>
        buildRecords(state.entities, entity => entity.entityId),

    /** Get a metrics dictionary (by id) */
    metricsRecords: (state): Record<number, IMetric> =>
        buildRecords(state.metrics, (metric) => metric.metricId),

    publishersRecords: (state): Record<number, IPublisher> =>
        buildRecords(state.publishers, (publisher) => publisher.publisherId),

    vendorsRecords: (state): Record<number, IVendor> =>
        buildRecords(state.vendors, (vendor) => vendor.vendorId),

    availablePublishers: (state): IPublisher[] =>
        state.publishers.filter(p => !p.isHidden),

    hiddenPublishers: (state): IPublisher[] =>
        state.publishers.filter(p => p.isHidden),

    touchPointsRecords: (state): Record<number, ITouchPoint> => {
        console.log(state.touchPoints);
        return buildRecords(state.touchPoints, touchPoint => touchPoint.touchPointId)
    }
        ,

    touchPointsHierarchy: (state): ITouchPoint[] => {
        const results: ITouchPoint[] = [];
        const lookup = buildRecords(state.touchPoints, touchPoint => touchPoint.touchPointId);

        for (const item of Object.values(lookup)) {
            if (item.parentTouchPointId == null) {
                results.push(item);
            }
            else {
                if (lookup[item.parentTouchPointId]) {
                    const itemChildren = lookup[item.parentTouchPointId].children ?? [];
                    itemChildren.push(item);

                    lookup[item.parentTouchPointId].children = itemChildren;
                }
            }
        }

        return results;
    },

    touchPointExceptionsRecords: (state): Record<number, ITouchPointException[]> =>
        buildLookup(state.touchPointExceptions, touchPointException => touchPointException.entityId),

    touchPointMetricExceptionsRecords: (state): Record<number, ITouchPointMetricException> =>
        buildRecords(state.touchPointMetricExceptions, touchPointMetricException => touchPointMetricException.touchPointMetricExceptionId),

    sourcesRecords: (state): Record<number, ISource> =>
        buildRecords(state.sources, source => source.sourceId),

    sourceEntitiesRecords: (state): Record<number, ISourceEntity> =>
        buildRecords(state.sourceEntities, sourceEntity => sourceEntity.sourceEntityId),

    regionsRecords: (state): Record<number, IRegion> =>
        buildRecords(state.regions, region => region.regionId),

    entityDisplayFormatRecords: (state): Record<number, IEntityDisplayFormat> =>
        buildRecords(state.entityDisplayFormats, entityDisplayFormat => entityDisplayFormat.entityDisplayFormatId),

    /** Get a list of regions by entityId */
    regionsByEntity: (state): Record<number, IRegion[]> =>
        buildLookup(state.regions, region => region.parentEntityId),
};

export { getters };
