const LOAD_ACCOUNT = "LOAD_ACCOUNT";
const LOGOUT = "LOGOUT";
const SET_USER = "SET_USER";
const SET_USER_ENTITIES = "SET_USER_ENTITIES";
const SET_SELECTED_ENTITY = "SET_SELECTED_ENTITY";

export {
    LOAD_ACCOUNT,
    LOGOUT,
    SET_USER,
    SET_USER_ENTITIES,
    SET_SELECTED_ENTITY,
};
