import Vue from "vue";
import { IUserGraph } from "@/models";
import { BaseClient } from "./BaseClient";

export class GraphService extends BaseClient {

    public constructor() {
        super("/graph");
    }

    public async search(prefix: string): Promise<IUserGraph[]> {
        try {
            const response = await this.apiClient.get<IUserGraph[]>(`/search/${prefix}`);
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            Vue.notify({
                title: "Echec",
                text: "Erreur lors de la récupération des utilisateurs",
                data: { status: "danger" },
            });
        }
        return null;
    }
}
