import { AxiosResponse } from "axios";
import { IExternalLinks, IFeatureFlags, ISettings } from "@/models";
import { BaseClient } from "./BaseClient";

class FeatureFlagsClient extends BaseClient {

    public constructor() {
        super("/feature-flags");
    }

    public async getAll(): Promise<IFeatureFlags> {
        return await this.get<IFeatureFlags>("", "Error while fetching feature flags");
    }

    public async update(settings: ISettings): Promise<AxiosResponse> {
        const response = await this.apiClient.post("", settings, {});

        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        else {
            throw response;
        }
    }

    public async updateExternalLinks(externalLinks: IExternalLinks): Promise<AxiosResponse> {
        const response = await this.apiClient.post("/external-links", externalLinks, {});

        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        else {
            throw response;
        }
    }
}

const featureFlagsClient = new FeatureFlagsClient();

export { FeatureFlagsClient, featureFlagsClient };
