import {
    IBrand,
    IBrandQuality,
    ICurrency,
    IEntity,
    IPublisher,
    ITouchPoint,
    ITouchPointCategory,
    IVendor,
    IPurchaseOrder,
    IRole,
    IAgency,
    ISource,
    IMetric,
    IRegion,
    ITouchPointMetric,
    IChannel,
    IVendorOutlet,
    IEntityDisplayFormat,
} from "@/models";
import { BaseClient } from "./BaseClient";
import { AxiosResponse } from "axios";

class ReferencesClient extends BaseClient {

    public constructor() {
        super("/references");
    }

    public async getAgencies(): Promise<IAgency[]> {
        const agencies = await this.get<IAgency[]>("/agencies", "Error while fetching agencies");
        return agencies ?? [];
    }

    public async getBrands(): Promise<IBrand[]> {
        const brands = await this.get<IBrand[]>("/brands", "Error while fetching brands");
        return brands ?? [];
    }

    public async getBrandQualities(): Promise<IBrandQuality[]> {
        const brandQualities = await this.get<IBrandQuality[]>("/brand-qualities", "Error while fetching brand qualities");
        return brandQualities ?? [];
    }

    public async getChannels(): Promise<IChannel[]> {
        const channels: IChannel[] = await this.get<IChannel[]>("/channels", "Error while fetching channels");
        return channels ?? [];
    }

    public async getCurrencies(): Promise<ICurrency[]> {
        const currencies: ICurrency[] = await this.get<ICurrency[]>("/currencies", "Error while fetching currencies");
        return currencies ?? [];
    }

    public async getEntities(): Promise<IEntity[]> {
        const entities = await this.get<IEntity[]>("/entities", "Error while fetching entities");
        return entities ?? [];
    }

    public async getMetrics(): Promise<IMetric[]> {
        const metrics = await this.get<IMetric[]>("/metrics", "Error while fetching metrics");
        return metrics ?? [];
    }

    public async getPurchaseOrders(): Promise<IPurchaseOrder[]> {
        const purchaseOrders = await this.get<IPurchaseOrder[]>("/purchase-orders", "Error while fetching purchase orders");
        return purchaseOrders ?? [];
    }

    public async getPublishers(): Promise<IPublisher[]> {
        const publishers = await this.get<IPublisher[]>("/publishers", "Error while fetching publishers");
        return publishers ?? [];
    }

    public async getRegions(): Promise<IRegion[]> {
        const regions = await this.get<IRegion[]>("/regions", "Error while fetching regions");
        return regions ?? [];
    }

    public async getRoles(): Promise<IRole[]> {
        const roles = await this.get<IRole[]>("/roles", "Error while fetching roles");
        return roles ?? [];
    }

    public async getSources(): Promise<ISource[]> {
        const sources = await this.get<ISource[]>("/sources", "Error while fetching sources");
        return sources ?? [];
    }

    public async getTouchPoints(): Promise<ITouchPoint[]> {
        const touchpoints = await this.get<ITouchPoint[]>("/touchpoints", "Error while fetching touchpoints");
        return touchpoints ?? [];
    }

    public async getTouchPointCategories(): Promise<ITouchPointCategory[]> {
        const touchpoints = await this.get<ITouchPointCategory[]>(
            "/touchpoint-categories", "Error while fetching touchpoint categories");
        return touchpoints ?? [];
    }

    public async getTouchPointMetrics(): Promise<ITouchPointMetric[]> {
        const touchPointMetrics = await this.get<ITouchPointMetric[]>("/touchpoint-metrics", "Error while fetching touchpoint metrics");
        return touchPointMetrics ?? [];
    }

    public async getTouchPointMetricsByTouchpoints(touchPointIds: number[]): Promise<ITouchPointMetric[]> {
        const url = this.buildTouchpointArrayParams(touchPointIds);
        const touchPointMetrics = await this.get<ITouchPointMetric[]>(`/touchpoint-metrics-by-touchpoint${url}`, "Error while fetching touchpoint metrics");
        return touchPointMetrics ?? [];
    }

    public async getVendors(): Promise<IVendor[]> {
        const vendors = await this.get<IVendor[]>("/vendors", "Error while fetching vendors");
        return vendors ?? [];
    }

    public async updateEntities(entities: IEntity[]): Promise<void> {
        return await this.put("/entities", entities, "Could not save entities");
    }

    public async createPublisher(publisher: Partial<IPublisher>): Promise<IPublisher[]> {
        return await this.post<IPublisher[]>("/publisher", publisher, "Could not create publisher" );
    }

    public async createOrUpdateVendorOutlet(vendorOutlets: IVendorOutlet): Promise<IVendor[]> {
        return await this.post<IVendor[]>("/vendor-outlets", vendorOutlets, "Could not create vendor and outlets");
    }

    public async createRegion(region: Partial<IRegion>): Promise<IRegion> {
        return await this.post<IRegion>("/region", region, "Could not create region" );
    }

    public async setPublisherObsolete(publisherId: number): Promise<IPublisher> {
        return await this.put<IPublisher>(`/obsolete-publisher/${publisherId}`, "Could not delete publisher");
    }

    public async setVendorObsolete(vendorId: number): Promise<IVendor[]> {
        return await this.put<IVendor[]>(`/obsolete-vendor/${vendorId}`, "Could not delete customer");
    }

    public async setRegionObsolete(regionId: number): Promise<IRegion> {
        return await this.put<IRegion>(`/obsolete-region/${regionId}`, "Could not delete region");
    }


    public async updateEntityHasPublisherList(entityId: number, hasPublisherList: boolean): Promise<void> {
        return await this.put("/has-publisher-list", {entityId, hasPublisherList}, "Could not update publisher active list");
    }

    public async updateEntityHasCustomerList(entityId: number, hasCustomerList: boolean): Promise<void> {
        return await this.put("/has-customer-list", {entityId, hasCustomerList}, "Could not update customer active list");
    }

    public async updateEntityHasRegions(entityId: number, hasRegions: boolean): Promise<void> {
        return await this.put("/has-regions", {entityId, hasRegions}, "Could not update entity has sub-markets");
    }

    public async updateEntityEnableSubMarketAggregation(entityId: number, subMarketAggregationEnabled: boolean): Promise<void> {
        return await this.put("/enable-sub-markets-aggregation", {entityId, subMarketAggregationEnabled}, "Could not update entity for enable sub market data aggregation");
    }

    public async updateEntityIsMehSyncEnabled(entityIds: number[], isMehSyncEnabled: boolean): Promise<void> {
        return await this.put("/meh-sync-enabled", {entityIds, isMehSyncEnabled}, "Could not update entity for MEH sync enabled");
    }

    public async updateEntityDisplayFormat(entityDisplayFormat: IEntityDisplayFormat): Promise<void> {
        return await this.put(`/entity-display-format/${entityDisplayFormat.entityDisplayFormatId}`, entityDisplayFormat, "Could not save entity display format");
    }

    public async getEntityDisplayFormats(): Promise<IEntityDisplayFormat[]> {
        const entityDisplayFormats = await this.get<IEntityDisplayFormat[]>("/entity-display-format", "Error while fetching entity display formats");
        return entityDisplayFormats ?? [];
    }

    public async setPublishersObsoleteByEntityId(entityId: number): Promise<IPublisher[]> {
        return await this.put<IPublisher[]>(`/obsolete-publishers/${entityId}`, "Could not delete publishers");
    }

    public async setCustomersObsoleteByEntityId(entityId: number): Promise<IVendor[]> {
        return await this.put<IVendor[]>(`/obsolete-customers/${entityId}`, "Could not delete customers");
    }

    public async setRegionsObsoleteByEntityId(entityId: number): Promise<IRegion[]> {
        return await this.put<IRegion[]>(`/obsolete-regions/${entityId}`, "Could not delete regions");
    }

    public async downloadReference(fileName: string, entityId?: number, refType?: string): Promise<AxiosResponse> {
        let url = "/download-ref-type";
        if (fileName.length > 0) {
            url += `?FileName=${fileName}`;
        }
        if (entityId) {
            url += `&EntityId=${entityId}`;
        }
        if (refType) {
            url += `&RefType=${refType}`;
        }
        return await this.downloadFile(url);
    }

    private buildTouchpointArrayParams(values: number[]): string {

        let url = "";
        if (values?.length > 0) {
            url = "?";
            let index = 0;
            for (const item of values) {
                if (index > 0) {
                    url += "&";
                }
                url += "touchPointIds=" + item;
                index++;
            }
        }
        return url;
    }
}

const referencesClient = new ReferencesClient();

export { ReferencesClient, referencesClient };
