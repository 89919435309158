import { NavigationGuard } from "vue-router";
import { Role } from "@/models";
import { store } from "@/store";
import { RouteNames } from "./RouteNames";
import { router } from ".";

/** Redirect to user's home based on role */
export const homeGuard: NavigationGuard = async (to, from, next) => {
    if (to.name !== RouteNames.home) {
        return next();
    }

    const userRole = store.state.account.user.roleId;

    if (userRole === Role.DataCollector || userRole > Role.LocalDataSteward) {
        router.replace({ name: RouteNames.campaignsBoard });
    }
    else if (userRole === Role.LocalDataSteward) {
        router.replace({ name: RouteNames.campaignValidation });
    }
    else {
        router.replace({ name: RouteNames.unauthorized });
    }
};
