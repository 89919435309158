import Vue from "vue";
import VueWait from "vue-wait";
import Vuelidate from "vuelidate";
import { dateFilter } from "vue-date-fns";
import "vue-class-component/hooks";
import { prModelDirective } from "@/directives";
import { vueAppInsights, vueGtm, vueHotjar } from "@/plugins";
import { router } from "@/router";
import "@/router/registerHooks";
import { store } from "@/store";
import App from "./App.vue";
import { settings } from "./settings";
import { applyPolyfills, defineCustomElements } from "@pernod-ricard/design-system/loader";

// Import global styles
import "@/assets/styles/global.scss";
import { vueNotification } from "./plugins/vue-notifications";

Vue.config.productionTip = false;
Vue.config.performance = settings.environment.toLowerCase() !== "production";

Vue.config.ignoredElements = [/pr-\w*/];

// Add custom directives
Vue.directive("pr-model", prModelDirective);

// Add custom filters
Vue.filter("date", dateFilter);

// Bind the custom elements to the window object
applyPolyfills().then(() => {
    defineCustomElements();
});

// Setup App Insights
vueAppInsights();

// Setup GTM
vueGtm();

// Setup Hotjar
// disable temporary cause trouble during component render
vueHotjar();

// Setup notifications service
vueNotification();

Vue.use(VueWait);
Vue.use(Vuelidate);

new Vue({
    router,
    store,
    wait: new VueWait({ useVuex: true }),
    render: h => h(App),
}).$mount("#app");
