import Vue from "vue";
import { LocalStorageNames } from "@/helpers";
import { accountClient } from "./AccountClient";
import { msalService } from "./MsalService";
import { v4 as uuidv4 } from "uuid";
import { store } from "@/store";
import { sha256 } from "js-sha256";


/* eslint-disable camelcase */

class AnalyticsService extends Vue {
    private sessionStorageSessionIdKey = "extract_sessionId";

    private hashSalt = process.env.VUE_APP_HASH_SALT as string;


    public async trackUserLogin(): Promise<void> {
        // Get last login date
        const lastLogin = localStorage.getItem(LocalStorageNames.userLastLogin);

        const lastLoginDate = new Date(lastLogin);
        const yesterday = this.getYesterday();

        if (new Date(lastLoginDate) < yesterday) {
            // Track login
            await accountClient.trackLogin();

            // Store last login date
            msalService.storeLoginDate();
        }
    }

    public clearLastLogin(): void {
        localStorage.removeItem(LocalStorageNames.userLastLogin);
    }

    public onCollectionTabClickEvent(tabName: string): void {
        this.pushToDataLayer({
            event: "campaign_menu",
            click_name: tabName,
        });
    }

    public onFilterClickEvent(pageType: string, position: string) {
        this.pushToDataLayer({
            event: "campaign_filter",
            page_type: pageType,
            filter_position: position,
        });
    }

    public onHelpMenuEvent(helpMenuItemName: string): void {
        this.pushToDataLayer({
            event: "help_menu",
            click_name: helpMenuItemName,
        });
    }

    public onKpiMetricSelectEvent(metricName: string): void {
        this.pushToDataLayer({
            event: "metric_filter",
            filter_name: metricName,
        });
    }

    public onLayoutCreated(): void {
        let sessionId = sessionStorage.getItem(this.sessionStorageSessionIdKey);

        if (!sessionId) {
            sessionId = uuidv4();
            sessionStorage.setItem(this.sessionStorageSessionIdKey, sessionId.toString());

            this.pushToDataLayer({
                event: "login",
                method: null,
            });
        }
    }

    public onPageView(path: string, title: string): void {
        this.pushToDataLayer({
            event: "Pageview",
            page_path: path,
            page_title: title,
        });
    }

    public onNamingBuilderLinkEvent(): void {
        this.pushToDataLayer({event: "name_builder_link"});
    }

    public onSaveAsDraftEvent(): void {
        this.pushToDataLayer({event: "save_as_a_draft"});
    }

    public onSendDataEvent(pageType: string): void {
        this.pushToDataLayer({
            event: "send_data",
            page_type: pageType,
        });
    }

    public onValidationTabClickEvent(tabName: string): void {
        this.pushToDataLayer({
            event: "data_validation_menu",
            click_name: tabName,
        });
    }


    private getYesterday(): Date {
        const today = new Date();
        return new Date(today.setDate(today.getDate() - 1));
    }

    private pushToDataLayer(event: Partial<IAnalyticEvent>): void {
        // Pseudonymization allowed if the user consents to the use of personal data
        event.user_id = sha256(store.state.account.user?.userId + this.hashSalt);
        event.user_type = store.state.account.user?.isInternal? "internal":"external";
        event.user_role = store.getters["account/userRole"]?.code;
        event.hq = null;
        const onlyOneMarketId = store.state.account.userEntities.length === 1? store.state.account.userEntities[0] : null;
        event.user_market = onlyOneMarketId ? store.state.data.entities.find(e => e.entityId === onlyOneMarketId).name : null;
        event.user_multi_market = store.state.account.userEntities.length > 1;
        event.user_department = null;

        if (this.$gtm.enabled()) {
            window.dataLayer?.push(event);
        }
    }
}

interface IAnalyticEvent {
    event: string;
    click_name: string;
    page_type: string;
    filter_position: string;
    filter_name: string;
    method: string;
    data_collection_type: string;
    user_id: string;
    user_type: string;
    user_role: string;
    hq: string;
    user_market: string;
    user_multi_market: boolean;
    user_department: string;
    page_path: string;
    page_title: string;

}

const analyticsService = new AnalyticsService();

export { analyticsService };


