import { ICurrency, IEntity } from "@/models";
import { store } from "@/store";

export const dateFormat = (): string => {
    const edt = store.state.data.entityDisplayFormats.find(edf => edf.entityId.toString() === store.state.account.selectedEntityId.toString());
    return edt?.dateFormat;
};

export const numberFormat = (): string => {
    const edt = store.state.data.entityDisplayFormats.find(edf => edf.entityId.toString() === store.state.account.selectedEntityId.toString());
    return edt.separator === "space" ? ("fr-FR") : ("en-En");
};

export const currencySymbolEntity = (entityId: number): string => {
    const edt = store.state.data.entityDisplayFormats.find(edf => edf.entityId.toString() === entityId.toString());
    if (edt.currencySymbol !== null && edt.currencySymbol !== "") {
        return edt.currencySymbol;
    }
    else {
        const entity: IEntity = store.getters["data/entitiesRecords"][entityId.toString()];
        const currency: ICurrency = store.getters["data/currenciesRecords"][entity?.currencyId];
        return currency?.symbol ?? `(${currency?.code})`;
    }
};

export const getDisplayMetricWithCurrency = (metric: string, currency: string): string => {
    const edt = store.state.data.entityDisplayFormats.find(edf => edf.entityId.toString() === store.state.account.selectedEntityId.toString());

    if (!edt.isCurrencyBefore) {
        return `${metric} ${currency}`;
    }
    else {
        return `${currency} ${metric}`;
    }
};
