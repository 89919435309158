import { ISourceEntity } from "@/models/ISourceEntity";
import { ISourceEntityReal } from "@/models/ISourceEntityReal";
import { AxiosResponse } from "axios";
import { BaseClient } from "./BaseClient";

class SourcEntityClient extends BaseClient {

    public constructor() {
        super("/source-entities");
    }

    public async getSourceEntities(): Promise<ISourceEntity[] | null> {
        return await this.get<ISourceEntity[] | null>("/", "Could not find source entities");
    }

    public async createSourceEntity(sourceEntity: ISourceEntityReal): Promise<ISourceEntity> {
        return await this.post("", sourceEntity, "Could not save source entity");
    }

    public async updateSourceEntity(sourceEntityId: number, sourceEntity: ISourceEntityReal): Promise<void> {
        return await this.post("/" + sourceEntityId, sourceEntity, "Could not update source entity");
    }

    public async deleteSourceEntity(sourceEntityId: number): Promise<AxiosResponse> {
        return await this.delete("/" + sourceEntityId);
    }
}

const sourceEntityClient = new SourcEntityClient();

export { SourcEntityClient, sourceEntityClient };
