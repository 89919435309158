import { AxiosResponse } from "axios";
import { BaseClient } from "./BaseClient";
import { ITouchPointException } from "@/models/ITouchPointException";
import { ITouchPointMetricException } from "@/models/ITouchPointMetricException";

class TouchPointAndMetricExceptionsClient extends BaseClient {

    public constructor() {
        super("/touchpoint-metric-exceptions");
    }

    public async getTouchPointExceptions(): Promise<ITouchPointException[] | null> {
        return await this.get<ITouchPointException[] | null>("/touchpoint-exceptions", "Could not find touchpoint exceptions");
    }

    public async getTouchPointExceptionsByEntityId(entityId: number): Promise<ITouchPointException[] | null> {
        return await this.get<ITouchPointException[] | null>(`/touchpoint-exceptions/${entityId}`, "Could not find touchpoint exceptions");
    }

    public async createOrUpdateTouchPointExceptions(touchPointExceptions: ITouchPointException[]): Promise<ITouchPointException[]> {
        return await this.post("touchpoint-exceptions", touchPointExceptions, "Could not save touchpoint exeptions");
    }

    public async deleteTouchPointExceptionsByTouchPointId(touchPointId: number): Promise<AxiosResponse> {
        return await this.delete("touchpoint-exceptions/" + touchPointId);
    }

    public async getTouchPointMetricExceptions(): Promise<ITouchPointMetricException[] | null> {
        return await this.get<ITouchPointMetricException[] | null>("", "Could not find touchpoint metric exceptions");
    }

    public async getTouchPointMetricExceptionsByEntityId(entityId: number): Promise<ITouchPointMetricException[] | null> {
        return await this.get<ITouchPointMetricException[] | null>(`${entityId}`, "Could not find touchpoint metric exceptions");
    }

    public async createOrUpdateTouchPointMetricExceptions(touchPointMetricExceptions: ITouchPointMetricException[]): Promise<ITouchPointMetricException[]> {
        return await this.post("", touchPointMetricExceptions, "Could not save touchpoint metric exeptions");
    }

    public async deleteTouchPointMetricExceptions(touchPointId: number, metricId: number): Promise<AxiosResponse> {
        return await this.delete("/" + touchPointId + "/" + metricId);
    }
}

const touchPointAndMetricExceptionsClient = new TouchPointAndMetricExceptionsClient();

export { TouchPointAndMetricExceptionsClient, touchPointAndMetricExceptionsClient };
