import { ICampaignsFilter, ICampaignsPage, IPreCampaign, ISaveCampaign } from "@/models";
import { BaseClient } from "./BaseClient";

class PreCampaignsClient extends BaseClient {

    public constructor() {
        super("/pre-campaign");
    }

    public async getCampaign(campaignId: number): Promise<IPreCampaign | null> {
        return await this.get<IPreCampaign | null>("/" + campaignId, "Could not find pre activation");
    }

    public async updatePreCampaign(campaignId: number, campaign: ISaveCampaign): Promise<void> {
        return await this.post("/" + campaignId, campaign, "Could not update activation");
    }

    public async savePreCampaignAsDraft(campaignId: number, campaign: ISaveCampaign): Promise<void> {
        return await this.post("/draft/" + campaignId, campaign, "Could not save as draft");
    }

    public async getPreCampaignsTotal(filter: ICampaignsFilter): Promise<number> {
        return null;
        /* XTRT-2210 - Improved DataValidation Performance
        const url = this.buildCampaignsQueryUrl("total", filter);
        return await this.get<number>(url, "Error while fetching activations"); */
    }

    public async getCampaigns(filter: ICampaignsFilter): Promise<ICampaignsPage> {
        const url = this.buildCampaignsQueryUrl("", filter);
        return await this.get<ICampaignsPage>(url, "Error while fetching activations");
    }


    private buildCampaignsQueryUrl(service: string, filter: ICampaignsFilter): string {
        const queryString = Object.keys(filter).map((key) => {
            const value = filter[key];
            if (value == null) {
                return "";
            }
            else if (Array.isArray(value)) {
                if (value.length > 0) {
                    return value.map(v => `${key}=${encodeURIComponent(v)}`).join("&");
                }
                else {
                    return "";
                }
            }
            else if (value instanceof Date) {
                return `${key}=${encodeURIComponent(value.toISOString())}`;
            }
            else {
                return `${key}=${encodeURIComponent(value)}`;
            }
        });

        const url = `${service}?${queryString.filter(s => s?.length > 0).join("&")}`;
        return url;
    }

}

const preCampaignsClient = new PreCampaignsClient();

export { PreCampaignsClient, preCampaignsClient };
