export const settings = {
    environment: process.env.VUE_APP_ENV as string,
    accessManagement: {
        url: process.env.VUE_APP_ACCESS_MANAGEMENT_URL as string,
    },
    appInsights: {
        instrumentationKey: process.env.VUE_APP_APP_INSIGHTS_INSTRUMENTATION_KEY as string,
    },
    azureAd: {
        clientId: process.env.VUE_APP_AZURE_AD_CLIENTID as string,
        tenantId: process.env.VUE_APP_AZURE_AD_TENANTID as string,
        instance: process.env.VUE_APP_AZURE_AD_INSTANCE as string,
        callbackPath: process.env.VUE_APP_AZURE_AD_CALLBACKPATH as string,
    },
    googleTagManager: process.env.VUE_APP_GOOGLE_TAG_MANAGER as string,
    hotjarId: process.env.VUE_APP_HOTJAR_ID as string,
    signalR: {
        url: process.env.VUE_APP_SIGNALR_URL as string,
        logLevel: process.env.VUE_APP_SIGNALR_LOG_LEVEL as string,
    },
    webApi: {
        baseUrl: process.env.VUE_APP_WEB_API_BASE_URL as string,
    },
    webSite: {
        title: process.env.VUE_APP_WEBSITE_TITLE as string,
    },
};
