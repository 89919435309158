import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { Role } from "@/models";
import { authGuard } from "./AuthGuard";
import { homeGuard } from "./HomeGuard";
import { roleGuard } from "./RoleGuard";
import { RouteNames } from "./RouteNames";
import { LocalStorageNames } from "@/helpers";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: "/",
        name: RouteNames.home,
        component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        beforeEnter: homeGuard,
        children: [
            {
                path: "/campaigns",
                name: RouteNames.campaignsBoard,
                component: () => import(/* webpackChunkName: "campaigns-board" */ "../views/dataCollection/campaignsOverview/CampaignsOverviewView.vue"),
                props: true,
                meta: {
                    title: "Activations board",
                    gtm: "Campaigns board",
                    menuItem: "data-collection",
                    requiredRoles: [Role.DataCollector, Role.LocalDataSteward, Role.CountryAdministrator],
                },
            },
            {
                path: "/campaigns/create/:entityId",
                name: RouteNames.createCampaign,
                component: () => import(/* webpackChunkName: "create-campaign" */ "../views/dataCollection/createCampaign/CreateCampaignView.vue"),
                props: (route) => ({
                    entityId: parseInt(route.params.entityId, 10),
                }),
                meta: {
                    title: "Create activation",
                    gtm: "Create campaign",
                    menuItem: "data-collection",
                    requiredRoles: [Role.DataCollector, Role.LocalDataSteward, Role.CountryAdministrator],
                },
            },
            {
                path: "/campaigns/edit/:campaignId",
                name: RouteNames.editCampaign,
                component: () => import(/* webpackChunkName: "create-campaign" */ "../views/dataCollection/createCampaign/CreateCampaignView.vue"),
                props: (route) => ({
                    campaignId: parseInt(route.params.campaignId, 10),
                }),
                meta: {
                    title: "Edit activation",
                    gtm: "Edit campaign",
                    menuItem: "data-collection",
                    requiredRoles: [Role.DataCollector, Role.LocalDataSteward, Role.CountryAdministrator],
                },
            },
            {
                path: "/campaigns/preCampaign/:campaignId",
                name: RouteNames.preCampaign,
                component: () => import(/* webpackChunkName: "pre-campaign" */ "../views/dataCollection/preCampaign/PreCampaignView.vue"),
                props: (route) => ({
                    campaignId: parseInt(route.params.campaignId, 10),
                }),
                meta: {
                    title: "Edit pre activation",
                    gtm: "Edit pre campaign",
                    menuItem: "data-collection",
                    requiredRoles: [Role.DataCollector, Role.LocalDataSteward, Role.CountryAdministrator],
                },
            },
            {
                path: "/campaigns/:campaignId",
                name: RouteNames.campaignDetails,
                component: () => import(/* webpackChunkName: "campaign-details" */ "../views/dataCollection/campaignDetails/CampaignDetailsView.vue"),
                props: (route) => ({
                    campaignId: parseInt(route.params.campaignId, 10),
                }),
                meta: {
                    title: "Activation details",
                    gtm: "Campaign details",
                    menuItem: "data-collection",
                    requiredRoles: [Role.DataCollector, Role.LocalDataSteward, Role.CountryAdministrator],
                },
            },
            {
                path: "/validation",
                redirect: { name: RouteNames.campaignValidation },
                meta: {
                    title: "Data validation",
                    menuItem: "data-validation",
                },
            },
            {
                path: "/validation/campaigns",
                name: RouteNames.campaignValidation,
                component: () => import(/* webpackChunkName: "campaigns-validation-overview" */ "../views/dataValidation/campaignsOverview/CampaignsOverviewView.vue"),
                meta: {
                    title: "Data validation - Activations",
                    gtm: "Data validation - Campaigns",
                    menuItem: "data-validation",
                    requiredRoles: [Role.LocalDataSteward, Role.CountryAdministrator, Role.Administrator],
                },
            },
            {
                path: "/validation/campaigns/:campaignId",
                name: RouteNames.campaignValidationDetails,
                component: () => import(/* webpackChunkName: "campaign-validation-details" */ "../views/dataValidation/campaignValidationDetails/CampaignValidationDetailsView.vue"),
                props: true,
                meta: {
                    title: "Data validation - Activation details",
                    gtm: "Data validation - Campaign details",
                    menuItem: "data-validation",
                    requiredRoles: [Role.LocalDataSteward, Role.CountryAdministrator, Role.Administrator],
                },
            },
            {
                path:"validation/campaign-data/:campaignValidationDataGroupId",
                name: RouteNames.campaignValidationData,
                component: () => import(/* webpackChunkName: "campaign-validation-data" */ "../views/dataValidation/CampaignValidationData/CampaignValidationDataView.vue"),
                props: true,
                meta: {
                    title: "Data validation - Activation data",
                    gtm: "Data validation - Campaign data",
                    menuItem: "data-validation",
                    requiredRoles: [Role.LocalDataSteward, Role.CountryAdministrator, Role.Administrator],
                },
            },
            {
                path: "/validation/touchpoints",
                name: RouteNames.touchPointsValidation,
                component: () => import(/* webpackChunkName: "touchpoints-validation-overview" */ "../views/dataValidation/touchpointsOverview/TouchpointsOverviewView.vue"),
                meta: {
                    title: "Data validation - Touchpoints",
                    gtm: "Data validation - Touchpoints",
                    menuItem: "data-validation",
                    requiredRoles: [Role.LocalDataSteward, Role.CountryAdministrator, Role.Administrator],
                },
            },
            {
                path: "/validation/touchpoints/:touchpointId",
                name: RouteNames.touchpointValidationDetails,
                component: () => import(/* webpackChunkName: "touchpoint-validation-details" */ "../views/dataValidation/touchpointValidationDetails/TouchpointValidationDetailsView.vue"),
                props: true,
                meta: {
                    title: "Data validation - Touchpoint details",
                    gtm: "Data validation - Touchpoint details",
                    menuItem: "data-validation",
                    requiredRoles: [Role.LocalDataSteward, Role.CountryAdministrator, Role.Administrator],
                },
            },
            {
                path: "/manage/users",
                name: RouteNames.manageUsers,
                component: () => import(/* webpackChunkName: "manage-users" */ "../views/manage/users/ManageUsersView.vue"),
                meta: {
                    title: "Manage users",
                    gtm: "Manage users",
                    menuItem: "manage",
                    requiredRoles: [Role.Administrator, Role.CountryAdministrator],
                },
            },
            {
                path: "/manage/source-users",
                name: RouteNames.manageSourceUsers,
                component: () => import(/* webpackChunkName: "manage-source" */ "../views/manage/sourceUsers/ManageSourceUsersView.vue"),
                meta: {
                    title: "Manage origin user by market",
                    gtm: "Manage origin user by market",
                    menuItem: "manage",
                    requiredRoles: [Role.Administrator, Role.CountryAdministrator],
                },
            },
            {
                path: "/manage/market-settings",
                name: RouteNames.manageMarketSettings,
                component: () => import(/* webpackChunkName: "manage-source" */ "../views/manage/marketSettings/MarketSettings.vue"),
                meta: {
                    title: "Manage market settings",
                    gtm: "Manage market settings",
                    menuItem: "manage",
                    requiredRoles: [Role.Administrator, Role.CountryAdministrator],
                },
            },
            {
                path:"/admin/settings",
                name: RouteNames.settings,
                component: () => import(/* webpackChunkName: "settings" */ "../views/admin/settings/Settings.vue"),
                meta: {
                    title: "Settings",
                    gtm: "Settings",
                    menuItem: "settings",
                    requiredRoles: [Role.Administrator],
                },
            },
            {
                path:"/admin/source-entities",
                name: RouteNames.manageSourceEntities,
                component: () => import(/* webpackChunkName: "source-entities" */ "../views/admin/sourceEntities/ManageSourceEntitiesView.vue"),
                meta: {
                    title: "Manage markets",
                    gtm: "Manage markets",
                    menuItem: "settings",
                    requiredRoles: [Role.Administrator],
                },
            },
            {
                path:"/admin/search-id-task",
                name: RouteNames.searchIdTask,
                component: () => import(/* webpackChunkName: "source-entities" */ "../views/admin/searchIdTask/SearchIdTask.vue"),
                meta: {
                    title: "Search ID task",
                    gtm: "Search ID task",
                    menuItem: "settings",
                    requiredRoles: [Role.Administrator],
                },
            },
            {
                path: "/admin/monitoring-dashboard",
                name: RouteNames.monitoringDashboard,
                component: () => import(/* webpackChunkName: "manage-source" */ "../views/admin/monitoringDashboard/MonitoringDashboard.vue"),
                meta: {
                    title: "Monitoring dashboard",
                    gtm: "Monitoring dashboard",
                    menuItem: "admin",
                    requiredRoles: [Role.Administrator, Role.CountryAdministrator],
                },
            },
            {
                path:"/user-settings",
                name: RouteNames.userSettings,
                component: () => import(/* webpackChunkName: "user settings" */ "../views/userSettings/UserSettings.vue"),
                meta: {
                    title: "User settings",
                    gtm: "UserSettings",
                    menuItem: "user-settings",
                    requiredRoles: [Role.Administrator, Role.CountryAdministrator, Role.LocalDataSteward],
                },
            },
        ],
    },
    {
        path: "/auth",
        redirect: "/",
    },
    {
        path: "/unauthorized",
        name: RouteNames.unauthorized,
        component: () => import(/* webpackChunkName: "unauthorized" */ "@/views/unauthorized/Unauthorized.vue"),
        meta: { anonymous: true },
    },
    {
        path: "/*",
        name: RouteNames.notFound,
        component: () => import(/* webpackChunkName: "notFound" */ "@/views/notFound/NotFound.vue"),
        meta: { anonymous: true },
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    if (from.name) {
        localStorage.setItem(LocalStorageNames.lastRouteName, from.name);
        localStorage.setItem(LocalStorageNames.lastRoutePath, from.path);
    }
    next();
});

/**
 * Redirects user to 'not found' page without changing path in url
 *
 * @param originalRoute Original route
 */
export const redirectToNotFound = (originalRoute: Route): void => {
    router.replace({ name: RouteNames.notFound, params: { 0: originalRoute.fullPath } });
};

router.beforeEach(authGuard);
router.beforeEach(roleGuard);

export { router, RouteNames };
