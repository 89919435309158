import * as msal from "@azure/msal-browser";
import { IUser } from "@/models";

interface IAccountState {
    msalAccount: msal.AccountInfo | null;
    user: IUser;
    userEntities: number[];
    selectedEntityId: number;
}

const initialState: IAccountState = {
    msalAccount: null,
    user: null,
    userEntities: [],
    selectedEntityId: null,
};

export { IAccountState, initialState };
