import { IExternalSyncInfo, IDmcSyncSettings, IMehSyncSettings, IMehSyncSource } from "@/models";
import { BaseClient } from "./BaseClient";

class ExternalSyncClient extends BaseClient {

    public constructor() {
        super("/external-sync");
    }

    public async getExternalSyncInfo(): Promise<IExternalSyncInfo[]> {
        return await this.get<IExternalSyncInfo[]>("", "Error while fetching external sync infos.");
    }

    public async getMehSyncSettings(): Promise<IMehSyncSettings> {
        return await this.get<IMehSyncSettings>("meh", "Error while fetching MEH sync settings.");
    }

    public async getDmcSyncSettings(): Promise<IDmcSyncSettings> {
        return await this.get<IDmcSyncSettings>("dmc", "Error while fetching DMC sync settings.");
    }

    public async updateMehSyncSettings(settings: IMehSyncSettings): Promise<void> {
        const response = await this.apiClient.post("meh", settings);

        if (response.status >= 200 && response.status < 300) {
            return;
        }
        else {
            throw response;
        }
    }

    public async updateMehSyncSources(settings: IMehSyncSource[]): Promise<void> {
        const response = await this.apiClient.post("meh/sources", settings);

        if (response.status >= 200 && response.status < 300) {
            return;
        }
        else {
            throw response;
        }
    }

    public async updateDmcSyncSettings(settings: IDmcSyncSettings): Promise<void> {
        const response = await this.apiClient.post("dmc", settings);

        if (response.status >= 200 && response.status < 300) {
            return;
        }
        else {
            throw response;
        }
    }
}

const externalSyncClient = new ExternalSyncClient();

export { ExternalSyncClient, externalSyncClient };
