import { settings } from "@/settings";
import Vue from "vue";
import Hotjar from "vue-hotjar";

export const vueHotjar = (): void => {
    Vue.use (Hotjar, {
        id: settings.hotjarId, // Hotjar Site ID
        isProduction: settings.environment.toLowerCase() === "production",
    });
};
