import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";
import { router } from "@/router";
import { settings } from "@/settings";

export const vueGtm = (): void => {
    Vue.use(VueGtm, {
        id: settings.googleTagManager,
        defer: false,
        enabled: settings.environment.toLowerCase() === "production",
        debug: false,
        loadScript: true,
        vueRouter: router,
    });
};
