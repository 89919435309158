import { NavigationGuard } from "vue-router";
import { store } from "@/store";
import { IUser, Role } from "@/models";
import { RouteNames } from ".";
import { WaitKeys } from "@/helpers";

export const roleGuard: NavigationGuard = async (to, from, next) => {
    // If user is not yet authenticated, let auth guard handle login.
    if (!store.getters["account/isAuthenticated"]) {
        return next();
    }

    // Get route role requirements

    // Search a matched route containing requirement details
    // starting from the last item in the array, which is the most specific
    let matchingRoute = null;
    for (let i = to.matched.length - 1; i >= 0; i--) {
        const route = to.matched[i];
        if (route.meta?.requiredRoles != null) {
            matchingRoute = to.matched[i];
            break;
        }
    }

    const requiredRoles: Role[] = matchingRoute?.meta.requiredRoles ?? [];

    // If routes requires no role, continue
    if (requiredRoles.length === 0) {
        return next();
    }

    // Handle role requirements

    // Fetch user data if not loaded
    if (!store.state.account.user) {
        store.dispatch("wait/start", WaitKeys.userData);

        await store.dispatch("account/fetchUser");

        await store.dispatch("account/fetchUserEntities");

        store.dispatch("wait/end", WaitKeys.userData);
    }

    const user: IUser = store.state.account.user;

    // If user has Administrator role, allow all routes
    if (user.roleId === Role.Administrator) {
        return next();
    }

    // Check user has any required role
    if (requiredRoles.some(r => r === user?.roleId)) {
        return next();
    }

    // If all else fails, user is redirect to Unauthorized page
    return next({ name: RouteNames.unauthorized });
};
