import groupBy from "lodash.groupby";
import keyBy from "lodash.keyby";

/** Build a record from an array */
export const buildRecords = <TValue>(array: TValue[], indexAccessor: (item: TValue) => PropertyKey): Record<PropertyKey, TValue> =>
    keyBy(array, indexAccessor) as Record<PropertyKey, TValue>;

/** Build a lookup from an array */
export const buildLookup = <TValue>(array: TValue[], indexAccessor: (item: TValue) => PropertyKey): Record<PropertyKey, TValue[]> =>
    groupBy(array, indexAccessor) as Record<PropertyKey, TValue[]>;

/** Build a lookup from an array pointing to a specific property */
export const buildPropLookup = <TKey extends keyof any, TItem, TValue>(array: TItem[], indexAccessor: (item: TItem) => TKey, valueAccessor: (item: TItem) => TValue): Record<TKey, TValue[]> =>
    array.reduce((hash, item) => {
        const itemKey = indexAccessor(item);
        const itemValue = valueAccessor(item);
        const itemsLookup = hash[itemKey] ?? [];

        hash = { ...hash };
        hash[itemKey] = itemValue != null ? [...itemsLookup, valueAccessor(item)] : [...itemsLookup];

        return hash;
    },
    {} as Record<TKey, TValue[]>);

/** Get unique values in array */
export const getUniqueValues = <TValue>(array: TValue[]): TValue[] => Array.from(new Set(array));

/** Get unique values in array using a discriminator */
export const getUniqueValuesById = <TKey, TValue>(array: TValue[], idAccessor: (item: TValue) => TKey): TValue[] => {
    const result: TValue[] = [];
    const set = new Set();

    for (const item of array) {
        const id = idAccessor(item);
        if (!set.has(id)) {
            set.add(id);
            result.push(item);
        }
    }

    return result;
};
