import { IDocumentUrls } from "@/models";
import { BaseClient } from "./BaseClient";

class DocumentsClient extends BaseClient {

    public constructor() {
        super("/documents");
    }

    public async getUrls(): Promise<IDocumentUrls> {
        return await this.get<IDocumentUrls>("", "Error while fetching documents urls");
    }
}

const documentsClient = new DocumentsClient();

export { DocumentsClient, documentsClient };
