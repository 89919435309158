class AbortControllerManager {
    controllers: Map<any, any>;

    constructor() {
        this.controllers = new Map();
    }

    // Crée un AbortController pour une URL donnée
    createController(url) {
        const controller = new AbortController();
        this.controllers.set(url, controller);
        return controller.signal;
    }

    // Vérifie et annule une requête pour une URL donnée
    abortControllerFor(url) {
        if (this.controllers.has(url)) {
            this.controllers.get(url).abort();
            this.controllers.delete(url);
        }
    }

    // Annule toutes les requêtes en cours
    abortAll() {
        for (const controller of this.controllers.values()) {
            controller.abort();
        }
        this.controllers.clear();
    }
}

// Singleton pour être utilisé partout dans l'application
export const abortControllerManager = new AbortControllerManager();