import Vue, { VNode } from "vue";
import { AdditionalColorPalettes } from "@pernod-ricard/design-system/dist/types/types/color.types";

// This component doesn't use class style definition
// because it doesn't support the 'functional' config on the .ts side
// and code is too complex to use only a <template functional> definition

export default Vue.extend({
    functional: true,
    props: {
        seed: { type: String, required: false },
        label: { type: String, required: false },
        singleAvatar: { type: Boolean, required: false, default: true },
    },
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    render(h, ctx): VNode {
        let sumSeedChar = 0;
        for (const char of ctx.props.seed) {
            sumSeedChar += char.charCodeAt(0);
        }

        let color: AdditionalColorPalettes = "blueberry";
        switch (sumSeedChar % 8) {
            case 0:
                color = "beet";
                break;

            case 1:
                color = "blueberry";
                break;

            case 2:
                color = "curacao";
                break;

            case 3:
                color = "ice";
                break;

            case 4:
                color = "rose";
                break;

            case 5:
                color = "safran";
                break;

            case 6:
                color = "light_grey";
                break;

            case 7:
                color = "dark_grey";
                break;

            default:
                color = "blueberry";
                break;
        }

        return <pr-avatar
            single-avatar={ctx.props.singleAvatar}
            background-palette={color}
            background-color="30"
            img-src=""
            size="small"
            mode="bright"
            name={ctx.props.label}
            class={ctx.data.staticClass} />;
    },
});
