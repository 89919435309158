import * as msal from "@azure/msal-browser";
import { MutationTree } from "vuex";
import { IUser } from "@/models";
import { IAccountState } from "./state";
import * as mutationTypes from "./mutations-types";

export const mutations: MutationTree<IAccountState> = {
    [mutationTypes.LOAD_ACCOUNT]: (state, payload: msal.AccountInfo) => {
        state.msalAccount = payload;
    },

    [mutationTypes.LOGOUT]: (state) => {
        state.msalAccount = null;
    },

    [mutationTypes.SET_USER]: (state, payload: IUser) => {
        state.user = payload;
    },

    [mutationTypes.SET_USER_ENTITIES]: (state, payload: number[]) => {
        state.userEntities = payload;
    },

    [mutationTypes.SET_SELECTED_ENTITY]: (state, payload: number) => {
        state.selectedEntityId = payload;
    },
};
