// Fix: trick TypeScript into thinking we use setTimeout from window, not Node
const { setTimeout } = window;

// Store this function in a reusable instance that you will call
// repeatedly for debounced calls
export const debounce = (fn: (...args) => any, delay: number): (this: any, ...args: any[]) => void => {
    let timeoutId: number;
    return (function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), delay);
    });
};
